import React, { useEffect, useState } from "react";
// Stylesheet
import "./SingleProductPage.scss";
//Images
import logo from "./../Img/logo.svg";
import share from "./../Img/share.svg";
import CuztomizeIcon from "./../Img/CustomizeIcon.svg";
import dummy from "./../Img/dummy shoes.svg";

import PrimaryBtn from "../Components/PrimaryBtn";
import FilterComponent from "../Components/FilterComponent";
import Backdrop from "../Components/Backdrop";
import Share from "../Components/Share";
import Components from "../Components/Components";
import LoadingPage from "./LoadingPage";

import { Unity, useUnityContext } from "react-unity-webgl";

const SingleProductPage = () => {
  const [loading, setLoading] = useState(true);
  const [shareModal, setShareModal] = useState(false);
  const [componentModal, setComponentModal] = useState(false);

  const s3Url = "";
  const compression = ".br";

  const [productNames, setProductName] = useState([
    { name: "Tip", color: "" },
    { name: "Mid Sole", color: "" },
    { name: "Angle", color: "" },
    { name: "Foxing", color: "" },
    { name: "Tongue", color: "" },
    { name: "Quarter", color: "" },
    { name: "Collar", color: "" },
    { name: "Front Strip", color: "" },
    { name: "Eye Stay", color: "" },
    { name: "Lining", color: "" },
    { name: "Quarter Lining", color: "" },
    { name: "Lower Sole", color: "" },
    { name: "Heel Pull Tab", color: "" },
    { name: "Laces", color: "" },
    { name: "Back Strap", color: "" },
  ]);
  const [productIndex, setProductIndex] = useState(0);
  const updateColor = (color) => {
    const colorArray = [...productNames];
    colorArray[productIndex].color = color;
    setProductName(colorArray);
  };

  const nextProduct = () => {
    if (productIndex >= productNames.length - 1) setProductIndex(0);
    else setProductIndex((prev) => prev + 1);
  };

  const prevProduct = () => {
    if (productIndex <= 0) setProductIndex(productNames.length - 1);
    else setProductIndex((prev) => prev - 1);
  };

  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    loadingProgression,
    isLoaded,
    UNSAFE__unityInstance,
  } = useUnityContext({
    loaderUrl: `${s3Url}/Build/ShoeConfig.loader.js`,
    dataUrl: `${s3Url}/Build/ShoeConfig.data${compression}`,
    frameworkUrl: `${s3Url}/Build/ShoeConfig.framework.js${compression}`,
    codeUrl: `${s3Url}/Build/ShoeConfig.wasm${compression}`,
  });

  useEffect(() => {
    if (isLoaded) sendMessage("Canvas", "ChangePosition");
  }, [isLoaded, sendMessage]);

  useEffect(() => {
    const loaderFull = document.querySelector(".loader-full");
    if (loaderFull)
      loaderFull.style.width = Math.round(loadingProgression * 100) + "%";
  }, [loadingProgression]);

  const shareModalHandler = () => {
    setShareModal((prev) => !prev);
  };

  const componentModalHandler = () => {
    setComponentModal((prev) => !prev);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <>
      {loading && <LoadingPage />}

      <div className="singleProductPage">
        <nav className="navigationBar">
          <img className="logo" src={logo} alt="logo" />
          <h2 className="productName">Breeze</h2>
          <div className="shareBtnDiv">
            <img
              onClick={shareModalHandler}
              className="shareIcon"
              src={share}
              alt="share"
            />
            <PrimaryBtn link={"/"} text={"Main Menu"} icon={CuztomizeIcon} />
          </div>
        </nav>
        <div style={{ height: "400px" }}>
          <Unity
            unityProvider={unityProvider}
            className=""
            style={{
              visibility: isLoaded ? "visible" : "hidden",
              width: "100%",
              height: "100%",
            }}
          />
          {!isLoaded && (
            <div className="sm:w-[60%] w-[400px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
              <p className="text-[#fff] mb-[0.5rem] self-start">
                Loading Application...
              </p>
              <div className="rounded-full border-2 border-white w-[100%] h-[10px]">
                <div
                  className={`rounded-full bg-[#fff] w-[0%] h-[100%] loader-full`}
                ></div>
              </div>
            </div>
          )}
        </div>

        {/* 3d modal Comes here */}
        <FilterComponent
          componentModalHandler={componentModalHandler}
          rightArrow={() => {
            sendMessage("Canvas", "NextEvent");
          }}
          leftArrow={() => {
            sendMessage("Canvas", "PreviousEvent");
          }}
          sendColor={(hexCode) => {
            sendMessage("Menu", "ColorChanger", hexCode);
          }}
          productNames={productNames}
          nextProduct={nextProduct}
          prevProduct={prevProduct}
          productIndex={productIndex}
          updateColor={updateColor}
        />
        {shareModal && (
          <Backdrop>
            <Share shareModalHandler={shareModalHandler} />
          </Backdrop>
        )}
        {componentModal && (
          <Backdrop>
            <Components
              productNames={productNames}
              componentModalHandler={componentModalHandler}
            />
          </Backdrop>
        )}
      </div>
    </>
  );
};
export default SingleProductPage;
