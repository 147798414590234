import React from "react";
// Stylesheet
import "./Share.scss";
//Images
import download from "./../Img/img.svg";
import pdf from "./../Img/pdf.svg";
import share from "./../Img/mail.svg";
import email from "./../Img/email.svg";
import down from "./../Img/dropdown.svg";
const Share = ({ shareModalHandler }) => {
  return (
    <div className="shareDiv">
      <h2 className="shareHeader">Share Your Design</h2>
      <p className="shareSubHeader">Save your Nike Shoes</p>
      <div className="shareBtnDiv">
        <button className="shareBtn">
          <p className="shareBtnText">Copy Link</p>
          <img src={share} alt="copy" className="shareBtnIcon" />
        </button>
        <button className="shareBtn">
          <p className="shareBtnText">Save as Image</p>
          <img src={download} alt="copy" className="shareBtnIcon" />
        </button>
        <button className="shareBtn">
          <p className="shareBtnText">Save as PDF</p>
          <img src={pdf} alt="copy" className="shareBtnIcon" />
        </button>
        <button className="shareBtn">
          <p className="shareBtnText">Email Your Design</p>
          <img src={email} alt="copy" className="shareBtnIcon" />
        </button>
      </div>
      <img
        onClick={shareModalHandler}
        className="closeBtn"
        src={down}
        alt="down"
      />
    </div>
  );
};
export default Share;
