import React from "react";
// Stylesheet
import "./Product.scss";
//component
import ProductCard from "../Components/ProductCard";
//Images
import logo from "./../Img/logo.svg";
import search from "./../Img/search.svg";
const Product = () => {
  return (
    <div className="productList">
      <nav className="productNavigation">
        <img src={logo} alt="logo" />
        <h2 className="productListHeader">Product List</h2>
        <div className="searchInputDiv">
          <input className="seachInput" type="text" name="" id="" />
          <img src={search} alt="search" className="searchIcon" />
        </div>
      </nav>
      <div className="subTabDiv">
        <p className="tab active">All</p>
        <p className="tab">Sneakers</p>
        <p className="tab">Slides</p>
      </div>
      <div className="cardDiv">
        <ProductCard name={"Breeze"} />
        <ProductCard name={"Breeze"} />
        <ProductCard name={"Breeze"} />
      </div>
    </div>
  );
};
export default Product;
