import React, { useState } from "react";
// Stylesheet
import "./FilterComponent.scss";
//Images
import right from "./../Img/right.svg";
import left from "./../Img/left.svg";
import component from "./../Img/component.svg";
import menu from "./../Img/menu.svg";
const FilterComponent = ({
  componentModalHandler,
  rightArrow,
  leftArrow,
  sendColor,
  productNames,
  prevProduct,
  nextProduct,
  productIndex,
  updateColor,
}) => {
  return (
    <div className="filterComponent">
      <div className="componentDiv">
        <img className="layer" src={component} alt="layers" />
        <button onClick={componentModalHandler} className="allComponentBtn">
          <img src={menu} alt="componentIcon" className="componentIcon" />
          <p className="componentText">All Components</p>
        </button>
      </div>
      <div className="subCategoryDiv">
        <div className="productNameDiv">
          <img
            className="arrowIcon"
            src={left}
            alt="<-"
            onClick={() => {
              leftArrow();
              prevProduct();
            }}
          />
          <h2 className="productName">{productNames[productIndex].name}</h2>
          <img
            className="arrowIcon"
            src={right}
            alt="->"
            onClick={() => {
              rightArrow();
              nextProduct();
            }}
          />
        </div>
        <p className="total">{productIndex + 1}/15</p>
        {/* <div className="materialDiv">
          <button className="material">Material</button>
          <button className="material">Material</button>
          <button className="material">Material</button>
        </div> */}
        <div className="colorDiv">
          <div
            style={{ background: "#d9a77a" }}
            className="color"
            onClick={() => {
              sendColor("#d9a77a");
              updateColor("#d9a77a");
            }}
          />
          <div
            style={{ background: "#E3CDBD" }}
            className="color"
            onClick={() => {
              sendColor("#E3CDBD");
              updateColor("#E3CDBD");
            }}
          />
          <div
            style={{ background: "#F79484" }}
            className="color"
            onClick={() => {
              sendColor("#F79484");
              updateColor("#F79484");
            }}
          />
          <div
            style={{ background: "#E9E9E9" }}
            className="color"
            onClick={() => {
              sendColor("#E9E9E9");
              updateColor("#E9E9E9");
            }}
          />
          <div
            style={{ background: "#7D5530" }}
            className="color"
            onClick={() => {
              sendColor("#7D5530");
              updateColor("#7D5530");
            }}
          />
          <div
            style={{ background: "#293E65" }}
            className="color"
            onClick={() => {
              sendColor("#293E65");
              updateColor("#293E65");
            }}
          />
          <div
            style={{ background: "#5CCEFE" }}
            className="color"
            onClick={() => {
              sendColor("#5CCEFE");
              updateColor("#5CCEFE");
            }}
          />
          <div
            style={{ background: "#5B77EE" }}
            className="color"
            onClick={() => {
              sendColor("#5B77EE");
              updateColor("#5B77EE");
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default FilterComponent;
