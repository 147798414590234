import React from "react";
// Stylesheet
import "./PrimaryBtn.scss";
import { Link } from "react-router-dom";
//Images
const PrimaryBtn = ({ link, text, icon }) => {
  return (
    <Link to={link} className="primaryBtn">
      <img className="btnIcon" src={icon} alt="btnIcon" />
      <p>{text}</p>
    </Link>
  );
};
export default PrimaryBtn;
