import React from "react";
// Stylesheet
import "./ProductCard.scss";
//Images
import cardBtnIcon from "./../Img/cardBtnIcon.svg";
//component
import PrimaryBtn from "./../Components/PrimaryBtn";
import pair from "./../Img/pair.svg";
const ProductCard = ({ name }) => {
  return (
    <div className="productCard">
      <div className="imageCard">
        {/* <div className="img"></div> */}
        <img className="img" src={pair} alt="" />
        <h2 className="productTitle">{name}</h2>
        <div className="colorDiv">
          <div style={{ background: "#D9A77A" }} className="color"></div>
          <div style={{ background: "#E3CDBD" }} className="color"></div>
          <div style={{ background: "#F79484" }} className="color"></div>
          <div style={{ background: "#5B77EE" }} className="color"></div>
        </div>
      </div>
      <h2 className="productDescriptionHeader">Description</h2>
      <p className="productDescription">
        Make a type specimen book it has survived not only five centuries but
        also type specimen book it has survived not also Make a type specimen
        book it has survived not
      </p>
      <div className="buttonDiv">
        <PrimaryBtn link={"/product"} icon={cardBtnIcon} text={"Customize"} />
      </div>
    </div>
  );
};
export default ProductCard;
