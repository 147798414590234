import React from "react";
// Stylesheet
import "./Components.scss";
//Images
import down from "./../Img/dropdown.svg";
const Components = ({ productNames, componentModalHandler }) => {
  return (
    <div className="components">
      <div className="headerDiv">
        <h2 className="componentsHeader">
          Components <span>15</span>
        </h2>
        <img
          onClick={componentModalHandler}
          className="closeBtn"
          src={down}
          alt="X"
        />
      </div>

      <div className="componentDiv">
        <div className="subcomponentDiv">
          {productNames.map((product, i) => (
            <p key={i} className="componentText">
              <span
                style={{
                  background: product.color ? product.color : "",
                  border: product.color
                    ? `1px solid ${product.color}`
                    : "1px solid gray",
                }}
                className="colorCircle"
              ></span>
              {product.name}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Components;
