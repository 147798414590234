import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Updated import statements
import SingleProductPage from "./Pages/SingleProductPage";
import Product from "./Pages/Product";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Product />} />
        <Route path="/product" element={<SingleProductPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
