import React, { useEffect, useState } from "react";
// Stylesheet
import "./LoadingPage.scss";
//Images
import LoadingLogo from "./../Img/loadingLogo.svg";
import LoadingLogoText from "./../Img/loadingLogoText.svg";
const LoadingPage = () => {
  const [loading, setLoading] = useState(0);
  useEffect(() => {
    const loadingPer = setInterval(() => {
      console.log(loading);

      if (loading === 100) {
        clearInterval(loadingPer);
      } else {
        setLoading((prev) => prev + 1);
      }
    }, 45);

    return () => {
      clearInterval(loadingPer); // Clear the interval when the component unmounts
    };
  });
  return (
    <div className="loading">
      <div className="loadingLogo">
        <img src={LoadingLogo} alt="logo" />
        <img src={LoadingLogoText} alt="rawfit studio" />
      </div>
      <p className="loadingProgression">
        Loading... <span>{loading}%</span>
      </p>
    </div>
  );
};
export default LoadingPage;
